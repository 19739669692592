* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* svg {
  fill: #7185b4;
} */

.fudurama-logo {
  fill: var(--blue);
  display: block;
  /* margin: auto; */
  /* max-width: 80px; */
}

a {
  text-decoration: none;
}
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.auth-form {
  margin: 50px auto;
  width: 300px;
  display: flex;
  flex-direction: column;
}

.auth-form * {
  padding: 10px;
  margin: 5px;
  width: 250px;
}

.type-res,
.type-sup,
.type-rep {
  display: none;
}

ul {
  list-style-type: none;
}

input {
  width: 100px;
}

h5 {
  padding: 0px 38px;
  display: inline;
}

table {
  border-spacing: 10px;
}

table,
th,
tr,
td {
  border: 1px solid black;
  padding: 5px;
}

h4 {
  margin: 0;
}

h1 {
  font-size: 30px;
}

.restaurant-info {
  margin: 20px 0;
}

.restaurant-info > p {
  padding: 0;
  margin: 0;
}

a {
  margin: 0 5px;
} */

.menu-item {
  /* padding: 0 40px; */
  margin: 0 1rem;
  user-select: none;
  cursor: pointer;
  border: none;
  /* overflow: scroll; */
  font-weight: normal;
}
.menu-item-wrapper.active {
  /* border: 1px blue solid; */
  /* font-weight: bold; */
}
.menu-item.active {
  /* border: 1px green solid; */
  font-weight: bold;
}

.scroll-menu-arrow {
  /* padding: 20px; */
  cursor: pointer;
}

/* Input style */
/* .input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.input-container .filled {
  transform: translate(0, -0.4rem) scale(0.8);
}

.input-container:focus-within label {
  transform: translate(0, -0.4rem) scale(0.8);
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 0.8rem) scale(1);
  transform-origin: top left;
  transition: 150ms linear 0ms;
  color: gray;
  background-color: white;
  padding: 0 0.5rem;
  font-size: 1.2rem;
  left: 1rem;
}

input:disabled {
  background-color: rgb(230, 230, 230);
}

input:disabled + label {
  background-color: rgb(230, 230, 230);
}

.input-container input {
  padding: 1rem 1.5rem;
  border: 1px solid #0000001f;
  border-radius: 2rem;
  min-width: 20rem;
  width: 100%;
  outline: none;
  transition: 150ms linear 0ms;
}

.input-container input:focus {
  box-shadow: 0 0 0 2px #79b1ff;
} */
