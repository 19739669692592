/*  */

/* @font-face {
  font-family: "Anderson Grotesk";
  src: url("./fonts/Anderson-Grotesk/AndersonGrotesk-Bold.otf");
  font-style: normal;
  font-weight: 400;
} */

:root {
  --blue: #3f5cb4;
  --faded-blue: #7185b4;
  --super-faded-blue: #b5c7da;
  --green: #03dac5;
  --orange: #fb633f;
  --mq: 860px;
  --card-shadow: 0px 0px 1px #000e6629, 0px 1px 6px #000e662d;
  --basic-shadow: 0px 1px 3px var(--faded-blue);
}

::-webkit-scrollbar {
  width: 0;
  display: none;
  height: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html,
body,
#root,
.App {
  height: 100%;
  -webkit-appearance: none;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  font-family: "Commissioner", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: rgb(251, 99, 63);
  background: linear-gradient(90deg, rgba(251, 99, 63, 0.1) 0%, rgba(113, 133, 180, 0.1) 100%); */
  /* background-color: #f6f1eb; */
  /* background-color: #e7eaed; */

  /* background-color: rgba(251, 99, 63, 0.1); */

  /* overflow: hidden; */

  /* background-image: url("./assets/fudurama-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center; */

  overscroll-behavior: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.colorful {
  background-image: url("./assets/fudurama-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
}

.colorless {
  background-color: #e7eaedfd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none; /* no underline */
  color: var(--blue);
  fill: var(--blue);
}

html {
  /* font-size: 1vw; */
  /* font-size: clamp(1rem, -0.875rem + 8.333vw, 3.5rem); */
  font-size: 12px;
}

/* @media (max-width: 860px) {
  html {
    font-size: 12px;
  }
} */
/* 
@media (min-width: 600px) and (max-width: 992px) {
  html {
    font-size: 8px;
  }
} */

/* @media (max-width: 600px) {
  html {
    font-size: 12px;
  }
  body {
    overflow: visible;
  }
}

@media (min-width: 601px) {
  html {
    font-size: 9px;
  }
}

@media (min-width: 840px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 1080px) {
  html {
    font-size: 11px;
  }
}

@media (min-width: 1320px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1560px) {
  html {
    font-size: 13px;
  }
}
@media (min-width: 1700px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1900px) {
  html {
    font-size: 15px;
  }
} */

/* @media (min-width: 1900px) {
  html {
    font-size: 16px;
  }
} */

/* h1 {
  font-size: 2.125rem;
  line-height: 2.25rem;
  font-weight: bold;
}

h2 {
  font-size: 2.125rem;
  line-height: 2.25rem;
  font-weight: normal;
}

h3 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: bold;
}

h4 {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: normal;
}

h5 {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: bold;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
} */

/* @media (min-width: 992px) and (max-width: 1440px) {
  h1 {
    font-size: 30px;
    line-height: 32px;
    font-weight: bold;
  }

  h2 {
    font-size: 30px;
    line-height: 32px;
    font-weight: normal;
  }

  h3 {
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
  }

  h4 {
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
  }

  h5 {
    font-size: 8px;
    line-height: 12px;
    font-weight: bold;
  }

  p {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (min-width: 1440px) {
  h1 {
    font-size: 34px;
    line-height: 36px;
    font-weight: bold;
  }

  h2 {
    font-size: 34px;
    line-height: 36px;
    font-weight: normal;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }

  h4 {
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
  }

  h5 {
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
} */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  /* margin: 0 1rem; */
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
